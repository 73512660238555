import React from "react";
import { transformText } from "../../../_metronic/_helpers/GeneralHelpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function ProfilePersonalData({
  persona,
  onChangeEstado,
  files,
  carrera,
  estado,
}) {
  const hasDocuments = files.length > 0;
  return (
    <>
      <h5>Informacion Personal</h5>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Nombre y Apellido
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.nombre}, ${persona?.apellido}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Numero Documento
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.numero_documento}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            CUIL
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.cuit}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Correo electronico
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.email}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Telefono
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.telefono ? persona?.telefono : "No declaró"}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Celular
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.celular ? persona?.celular : "No declaró"}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Direccion
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.direccion}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Localidad
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.localidad}`}
          />
        </div>
        <div className="col-md-6 col-sm-12 col-12 mb-5">
          <label className="form-label" htmlFor="">
            Provincia
          </label>
          <input
            className="form-control"
            type="text"
            disabled
            value={`${persona?.provincia}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-sm-12 col-12 mb-5">
          <h5>Carrera a Ingresar</h5>
          <p>{carrera}</p>
        </div>
      </div>
      <h5>Documentacion</h5>
      {hasDocuments ? (
        <div className="row mt-5">
          {files.map((document, key) => (
            <a
              key={key}
              className="col-xl-3 col-md-4 col-sm-12 col-12 pdf-box mb-5"
              href={document.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="pdf-box__content">
                <p className="pdf-box-content__title">
                  {transformText(document.name)}
                </p>
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Eye.svg")}
                  ></SVG>
                </span>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <p>No hay documentos disponibles.</p>
      )}
      <h5>Situación de Ingreso</h5>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-12 col-xs-12">
          <select
            className="form-control"
            name="estado_ingresante"
            id="estado_ingresante"
            onChange={onChangeEstado}
            defaultValue={`${estado}`}
          >
            <option value="-1">Seleccionar un valor...</option>
            <option value="1">Pendiente</option>
            <option value="2">Aprobado</option>
            <option value="3">Rechazado</option>
            <option value="4">Por empresa</option>
            <option value="5">Por Beca</option>
            <option value="6">Lista de espera</option>
          </select>
        </div>
      </div>
    </>
  );
}
