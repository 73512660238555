import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
export default function SituacionConcubinato({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  niveles
}) {
  const situacionConyuge =
    values.situacion_conyuge.concubinato_matrimonio === "1";
  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/add-user.svg")}
            />
          </span>
        </span>
        <h3>Situación del Conyuge</h3>
      </div>
      <div className="row mb-5">
        <div className="col-md-12 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            ¿Se encuentra en concubinato/matrimonio?
          </label>
          <div className="flex-column">
            <div className="flex-row">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={
                  values.situacion_conyuge.concubinato_matrimonio === "1"
                }
                value="1"
                onChange={() =>
                  handleChange("situacion_conyuge.concubinato_matrimonio")("1")
                }
              />{" "}
              <label htmlFor="">Si</label>
            </div>
            <div className="flex-row">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={
                  values.situacion_conyuge.concubinato_matrimonio === "0"
                }
                value="0"
                onChange={() =>
                  handleChange("situacion_conyuge.concubinato_matrimonio")("0")
                }
              />{" "}
              <label htmlFor="">No</label>
            </div>
          </div>
          {touched.situacion_conyuge?.concubinato_matrimonio &&
                errors.situacion_conyuge?.concubinato_matrimonio ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_conyuge.concubinato_matrimonio}
                  </div>
                </div>
              ) : null}
        </div>
      </div>
      {situacionConyuge && (
        <>
          <div className="row mb-5 transicion">
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <div className="row flex-column">
                <div className="col-12">
                  <label className="form-label">
                    Nivel de Estudios del Conyuge
                  </label>
                  <select
                    className={`form-control ${
                      touched.situacion_conyuge?.nivel_estudio_id_padre && errors.situacion_conyuge?.nivel_estudio_id_padre ? "is-invalid" : ""
                    }`}
                    name="situacion_conyuge.nivel_estudio_id_padre"
                    value={values.situacion_conyuge.nivel_estudio_id_concubinato}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="nivel_estudio_id_concubinato"
                  >
                      <option value="-1">Seleccione una opcion...</option>
                      {niveles.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <option value={item.id}>{item.descripcion}</option>
                        </React.Fragment>
                      ))}
                  </select>
                  {touched.situacion_conyuge?.nivel_estudio_id_concubinato &&
                errors.situacion_conyuge?.nivel_estudio_id_concubinato ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_conyuge.nivel_estudio_id_concubinato}
                  </div>
                </div>
              ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Ocupacion del conyuge
              </label>
              <input
                type="text"
                name="situacion_conyuge.ocupacion_concubinato"
                value={values.situacion_conyuge.ocupacion_concubinato}
                onChange={handleChange}
                onBlur={handleBlur}
                id="ocupacion_concubinato"
                className={`form-control ${
                  touched.situacion_conyuge?.ocupacion_concubinato && errors.situacion_conyuge?.ocupacion_concubinato ? "is-invalid" : ""
                }`}
              />
              {touched.situacion_conyuge?.ocupacion_concubinato &&
                errors.situacion_conyuge?.ocupacion_concubinato ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_conyuge.ocupacion_concubinato}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
