import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Checkbox } from "../../../../../_metronic/_partials/controls";
import Select from "react-select-virtualized";

export default function EstudiosSecundarios({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleIdEstudio,
  escuelas,
}) {
  const [jurisdicciones] = React.useState(
    Array.from(new Set(escuelas.map((item) => item.jurisdiccion)))
  );
  const [filterEscuelas, setFiltrosEscuelas] = React.useState(escuelas);
  const [cantSelect, setCantSelect] = React.useState(true);
  const [doestExist, setDoestExist] = React.useState(false);
  const [reservedId, setReservedId] = React.useState();

  const handleSelectJurisdiccion = (e) => {
    const _filter = escuelas
      .filter((item) => item.jurisdiccion === e.target.value)
      .sort((first, sec) => first.nombre.localeCompare(sec.nombre));
    setFiltrosEscuelas(_filter);
    setCantSelect(false);
  };

  const handleEscuela = (e) => {
    setReservedId(e.target.value);
    handleChange(e);
  };

  const handleNotFount = (e) => {
    const isChecked = e.target.checked;
    const escuelaIdValue = isChecked ? -1 : reservedId;
    setDoestExist(isChecked);
    handleIdEstudio(escuelaIdValue);
  };

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Shopping/settings.svg")}
            />
          </span>
        </span>
        <h3>Estudios Secundarios</h3>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-4 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            Jurisdiccion
          </label>
          {jurisdicciones.length > 0 && (
            <select
              className={`form-control`}
              onChange={handleSelectJurisdiccion}
              disabled={doestExist}
            >
              <option value="-1">Seleccione una jurisdiccion...</option>
              {jurisdicciones?.map((item, idx) => (
                <React.Fragment key={idx}>
                  <option value={item}>{item}</option>
                </React.Fragment>
              ))}
            </select>
          )}
        </div>
        <div className="col-xl-8 col-md-8 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            Establecimiento
          </label>
          <Select
            isSearchable={true}
            label="Elige un establecimiento..."
            name="estudio_secundario.escuela_id"
            id="estudio_secundario.escuela_id"
            options={filterEscuelas?.map((item) => ({
              value: item.id,
              label: item.nombre + " - " + item.localidad,
            }))}
            onChange={(selectedOption) => {
              const e = {
                target: {
                  name: "estudio_secundario.escuela_id",
                  value: selectedOption ? selectedOption.value : -1,
                },
              };
              handleEscuela(e);
            }}
            defaultValue={filterEscuelas?.find(
              (item) =>
                parseInt(item.id) ===
                parseInt(values.estudio_secundario?.escuela_id)
            )}
            isDisabled={cantSelect || doestExist}
          />
          {touched.estudio_secundario?.escuela_id &&
          errors.estudio_secundario?.escuela_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.estudio_secundario.escuela_id}
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-12 col-sm-12 col-12 mb-5 d-flex flex-row">
          <label htmlFor="" className="form-label mr-5">
            No encuentro mi escuela
          </label>
          <Checkbox onChange={handleNotFount}></Checkbox>
        </div>
        {doestExist && (
          <>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Pais
              </label>
              <input
                type="text"
                name="estudio_secundario.pais"
                id="estudio_secundario.pais"
                value={values.estudio_secundario.pais}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`form-control ${
                  touched.estudio_secundario?.pais &&
                  errors.estudio_secundario?.pais
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.pais &&
              errors.estudio_secundario?.pais ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.pais}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Provincia
              </label>
              <input
                type="text"
                name="estudio_secundario.provincia"
                id="estudio_secundario.provincia"
                value={values.estudio_secundario.provincia}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`form-control ${
                  touched.estudio_secundario?.provincia &&
                  errors.estudio_secundario?.provincia
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.provincia &&
              errors.estudio_secundario?.provincia ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.provincia}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Localidad
              </label>
              <input
                type="text"
                name="estudio_secundario.localidad"
                id="estudio_secundario.localidad"
                value={values.estudio_secundario.localidad}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  touched.estudio_secundario?.localidad &&
                  errors.estudio_secundario?.localidad
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.localidad &&
              errors.estudio_secundario?.localidad ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.localidad}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Nombre Establecimiento
              </label>
              <input
                type="text"
                id="estudio_secundario.colegio"
                name="estudio_secundario.colegio"
                value={values.estudio_secundario.colegio}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`form-control ${
                  touched.estudio_secundario?.colegio &&
                  errors.estudio_secundario?.colegio
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.colegio &&
              errors.estudio_secundario?.colegio ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.colegio}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Direccion Establecimiento
              </label>
              <input
                type="text"
                id="estudio_secundario.direccion_colegio"
                name="estudio_secundario.direccion_colegio"
                value={values.estudio_secundario.direccion_colegio}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`form-control ${
                  touched.estudio_secundario?.direccion_colegio &&
                  errors.estudio_secundario?.direccion_colegio
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.direccion_colegio &&
              errors.estudio_secundario?.direccion_colegio ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.direccion_colegio}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Tipo de Escuela
              </label>
              <input
                type="text"
                id="estudio_secundario.tipo_colegio"
                name="estudio_secundario.tipo_colegio"
                value={values.estudio_secundario.tipo_colegio}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`form-control ${
                  touched.estudio_secundario?.tipo_colegio &&
                  errors.estudio_secundario?.tipo_colegio
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.estudio_secundario?.tipo_colegio &&
              errors.estudio_secundario?.tipo_colegio ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.estudio_secundario.tipo_colegio}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
        <div className="col-md-4 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            Orientacion
          </label>
          <input
            type="text"
            id="estudio_secundario.orientacion"
            name="estudio_secundario.orientacion"
            value={values.estudio_secundario.orientacion}
            onBlur={handleBlur}
            onChange={handleChange}
            className={`form-control ${
              touched.estudio_secundario?.orientacion &&
              errors.estudio_secundario?.orientacion
                ? "is-invalid"
                : ""
            }`}
          />
          {touched.estudio_secundario?.orientacion &&
          errors.estudio_secundario?.orientacion ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.estudio_secundario.orientacion}
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-4 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            Año de Egresado
          </label>
          <input
            type="text"
            id="estudio_secundario.anio_graduacion"
            name="estudio_secundario.anio_graduacion"
            value={values.estudio_secundario.anio_graduacion}
            onBlur={handleBlur}
            onChange={handleChange}
            className={`form-control ${
              touched.estudio_secundario?.anio_graduacion &&
              errors.estudio_secundario?.anio_graduacion
                ? "is-invalid"
                : ""
            }`}
          />
          {touched.estudio_secundario?.anio_graduacion &&
          errors.estudio_secundario?.anio_graduacion ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.estudio_secundario.anio_graduacion}
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-4 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            Años en recibirse
          </label>
          <input
            type="text"
            id="estudio_secundario.anios_cursados"
            name="estudio_secundario.anios_cursados"
            value={values.estudio_secundario.anios_cursados}
            onBlur={handleBlur}
            onChange={handleChange}
            className={`form-control ${
              touched.estudio_secundario?.anios_cursados &&
              errors.estudio_secundario?.anios_cursados
                ? "is-invalid"
                : ""
            }`}
          />
          {touched.estudio_secundario?.anios_cursados &&
          errors.estudio_secundario?.anios_cursados ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.estudio_secundario.anios_cursados}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
