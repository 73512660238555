import React from "react";
import { useState } from "react";
import CustomModal from "../components/carreras/CustomModal";
import { useFetchGetCarreras } from "./Usuarios/hooks/UsersHooks";
import Loader from "../components/registerwizard/Loader/Loader";
import { renderCarrerCards } from "../components/carreras/RenderCarrerCards";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import ErrorMessage from "../components/General/ErrorMessage";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function CarrerChoosing(props) {
  const { persona } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.user);
  const [showCustom, setShowCustom] = useState(false);
  const [carreers, setCarreers] = useState(false);
  const [error, setError] = useState(false);
  const [loadingInstances, setLoadingInstances] = useState(true);

  const { comboCarreras } = useFetchGetCarreras(
    persona,
    user.id,
    setError,
    setLoadingInstances
  );
  const handleShowCustom = (value, instancias) => {
    setShowCustom(value);
    if (instancias != null) {
      setCarreers(instancias);
    }
  };

  if (error) {
    return (
      <>
        <ErrorMessage onLoad={true}></ErrorMessage>
      </>
    );
  } else if (!Object.keys(comboCarreras).length > 0) {
    if (loadingInstances) {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="card card-custom">
                  <div className="container-fluid">
                    <div className="row">
                      <Loader></Loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="card card-custom">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12 text-center p-5">
                        <span className="svg-icon svg-icon-primary svg-icon-lg">
                          <span className="svg-icon svg-icon-primary svg-icon-10x">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/sadly.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                        <h1>
                          No se encuentran instancias abiertas en este momento
                        </h1>
                        <h5>
                          Consulte las proximas en fechas
                          <a
                            href="https://www.frgp.utn.edu.ar/"
                            style={{ color: "blue" }}
                          >
                            https://www.frgp.utn.edu.ar/
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="card card-custom">
              <div className="container-fluid">
                <div className="row">
                  {renderCarrerCards(comboCarreras, handleShowCustom)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          persona={persona}
          arr={carreers}
          show={showCustom}
          handleShowCustom={handleShowCustom}
        ></CustomModal>
      </div>
    );
  }
}
