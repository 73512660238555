export const dateFormatter = (value) => {
  const fechaOriginal = new Date(value); // Esta es tu fecha original

  const year = fechaOriginal.getFullYear();
  const month = String(fechaOriginal.getMonth() + 1).padStart(2, "0"); // +1 porque los meses van de 0 a 11
  const day = String(fechaOriginal.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const isExpired = (value) => {
  const currDate = new Date();
  const expiredDate = new Date(value);
  return expiredDate > currDate ? true : false;
};
