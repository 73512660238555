import React from "react";
import { Modal } from "react-bootstrap";
import BubbleBackground from "./BubbleBackground";
import { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ModalInitWizard() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show}>
      <div
        className="body-description"
        style={{ padding: "0 !important", borderRadius: "5px" }}
      >
        <BubbleBackground>
          <div className="">
            <div className="row">
              <div className=" col-md-12 col-sm-12 col-12 col-xs-12">
                <div className="padder">
                  <h2 className="text-center header-description">
                    Bienvenido al registro del Sistema de Ingreso
                  </h2>
                  <div className="inner-body">
                    <p>
                      Tendras que llenar tus datos a medida que vayas avanzando
                      con el registro
                    </p>
                    <p>
                      Por favor, te pedimos que tengas a mano los siguientes
                      elementos en forma digtal (
                      <span className="red-remarker">
                        <span className="svg-icon svg-icon-sm">
                          <span className="svg-icon svg-icon-1x">
                            <SVG
                              style={{
                                fontSize: "2px",
                              }}
                              src={toAbsoluteUrl("media/svg/files/jpg.svg")}
                            ></SVG>
                          </span>
                        </span>
                        .jpg,{" "}
                      </span>
                      <span className="red-remarker">
                        <span className="svg-icon svg-icon-sm">
                          <span className="svg-icon svg-icon-1x">
                            <SVG
                              style={{
                                fontSize: "2px",
                              }}
                              src={toAbsoluteUrl("media/svg/files/pdf.svg")}
                            ></SVG>
                          </span>
                        </span>
                        .pdf,{" "}
                      </span>
                      )
                    </p>
                    <ul className="custom-unorderer">
                      <li className="red-remarker">DNI</li>
                      <li className="red-remarker">CUIT</li>
                      <li className="red-remarker">
                        CUD (En caso de contar con discapacidad)
                      </li>
                      <li className="red-remarker">PARTIDA DE NACIMIENTO</li>
                      <li className="red-remarker">
                        Certificado Laboral (En caso de encontrarse actualmente
                        trabajando)
                      </li>
                    </ul>
                    <p>
                      Los mismos se iran señalizando para saber cuando subir
                      cada archivo correspondiente
                    </p>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      onClick={handleClose}
                      className="btn btn-primary btn-md"
                    >
                      Comenzar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BubbleBackground>
      </div>
    </Modal>
  );
}
