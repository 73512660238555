import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Image } from "react-bootstrap";
import { CarrerasNewArr } from "./exampleCarreras";

function CarrerCard({ tipo, handleShowCustom }) {
  return (
    <div
      className={`carrer-card tc_${tipo.tipo_carrera_id}`}
      onClick={() => handleShowCustom(true, tipo.instancias)}
    >
      <div className="carrer-title">
        <span style={{ fontSize: "20px" }}>{tipo.tipo_carrera}</span>
      </div>
      <div className="carrer-img">
        <Image
          className=""
          src={toAbsoluteUrl(
            `/media/image-icons/tc_${tipo.tipo_carrera_id}.png`
          )}
        ></Image>
      </div>
    </div>
  );
}

export function renderCarrerCards(tipos_carreras, handleShowCustom) {
  return Object.keys(tipos_carreras).map((tipo, index) => {
    return (
      <div key={index} className={`col-md-6 col-sm-12 col-12 my-5`}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 my-5">
            <CarrerCard
              tipo={tipos_carreras[tipo]}
              handleShowCustom={handleShowCustom}
            />
          </div>
        </div>
      </div>
    );
  });
}
