import axios from "axios";

const GET_CARRERAS = `${process.env.REACT_APP_DOMAIN_NAME}/api/instancias/get_all_bff/`;
const INSTANCE = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/`;
const PERSONAS = `${process.env.REACT_APP_DOMAIN_NAME}/api/personas/`;
const GET_INSTANCIA_BY_PERSONA = `${process.env.REACT_APP_DOMAIN_NAME}}/api/inscriptos/get_by_persona/`;
const GET_DOCS = `${process.env.REACT_APP_DOMAIN_NAME}}/api/files/get_docs_persona/`;
export const GET_USERDETAILS = `${process.env.REACT_APP_DOMAIN_NAME}/api/personas/get_by_user/`;
const GET_CURSES = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/get_by_persona/`;
const GET_SITUATIONLABORAL = `${process.env.REACT_APP_DOMAIN_NAME}/api/situacion-laboral/`;
const FILES = `${process.env.REACT_APP_DOMAIN_NAME}/api/files/delete/`;
const SET_STATUS = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/set_estado/`;

export const getCarreras = async () => {
  try {
    const res = await axios.get(GET_CARRERAS);
    return res;
  } catch (error) {
    return null;
  }
};

export const inscribeme = async (id, instance, name) => {
  return await axios.post(INSTANCE, {
    persona_id: id,
    instancia_id: instance,
    carrera: name,
    es_beca: 0,
    abona_empresa: 0
  });
};

export const updateInformation = async (persona) => {
  try {
    return await axios.put(PERSONAS + persona.id, {
      nombre: persona.nombre,
      apellido: persona.apellido,
      tipo_documento: persona.tipo_documento,
      numero_documento: persona.numero_documento,
      cuit: persona.cuit,
      fecha_nacimiento: persona.fecha_nacimiento,
      lugar_nacimiento: persona.lugar_nacimiento,
      pais_id: persona.pais_id,
      nacionalidad_id: persona.nacionalidad_id,
      genero_id: persona.genero_id,
      tipo_sangre: persona.tipo_sangre,
      estado_civil: persona.estado_civil,
      hijos: persona.hijos,
      direccion: persona.direccion,
      piso: persona.piso ? persona.piso : "",
      departamento: persona.departamento ? persona.departamento : "",
      provincia: persona.provincia,
      localidad: persona.localidad,
      telefono: persona.telefono ? persona.telefono : "",
      celular: persona.celular,
      email: persona.email,
    });
  } catch (e) {
    throw e;
  }
};

export const updateJobSituation = async (values, persona) => {
  return await axios.put(`${GET_SITUATIONLABORAL}${persona}`, values);
};

export const getMyInstances = async (id) => {
  return await axios.get(`${GET_INSTANCIA_BY_PERSONA}${id}`);
};

export const getDocuments = async (id) => {
  return await axios.get(`${GET_DOCS}${id}`);
};

export const getMiInfo = async (id) => {
  return await axios.get(`${GET_USERDETAILS}${id}`);
};

export const getMyCurses = async (person_id) => {
  return await axios.get(`${GET_CURSES}${person_id}`);
};

export const getMyLaboralSituation = async (person_id) => {
  return await axios.get(`${GET_SITUATIONLABORAL}get_by_persona/${person_id}`);
};

export const deleteFileWithId = async (file_id) => {
  return await axios.delete(`${FILES}${file_id}`);
};

export const cambiarEstado = async (id, values) => {
  return await axios.put(`${SET_STATUS}${id}`, {
    estado_id: values.estado,
    observacion: values.obs,
  });
};
